<template>
  <div id="app" :style="containerStyle">
    <section data-name="start" data-bg="white" class="slide slide--start">
      <p>Meine Mama ist die beste Mama</p>
      <p class="arrow" :class="{ hidden: !showArrow }">↓</p>
    </section>

    <section data-name="musik" data-bg="hsl(15, 75%, 90%)" class="slide slide--musik">
      <img src="@/assets/img/musik.png" alt="Mama hört Musik" />
      <p>Meine Mama hört mit mir Musik.</p>
    </section>

    <section data-name="aufpassen" data-bg="hsl(45, 70%, 88%)" class="slide slide--aufpassen">
      <img src="@/assets/img/aufpassen.png" alt="Mama passt auf" />
      <p>Meine Mama passt auf, dass ich nichts anstelle.</p>
    </section>

    <section data-name="klavier" data-bg="hsl(110, 50%, 90%)" class="slide slide--klavier">
      <img src="@/assets/img/klavier.png" alt="Mama spielt Klavier" />
      <p>Meine Mama spielt mit mir Klavier.</p>
    </section>

    <section data-name="schulweg" data-bg="hsl(175, 70%, 90%)" class="slide slide--schulweg">
      <img src="@/assets/img/schulweg.png" alt="Mama sichert Schulweg" />
      <p>Meine Mama macht meinen Schulweg sicherer.</p>
    </section>

    <section data-name="zuhören" data-bg="hsl(220, 70%, 90%)" class="slide slide--zuhören">
      <img src="@/assets/img/zuhoeren.png" alt="Mama hört zu" />
      <p>Meine Mama hört mir zu.</p>
    </section>

    <section data-name="computer" data-bg="hsl(300, 40%, 90%)" class="slide slide--computer">
      <img src="@/assets/img/computer.png" alt="Mama spielt Computer" />
      <p>Meine Mama spielt mit mir Computer.</p>
    </section>

    <section data-name="finale" data-bg="hsl(352, 45%, 85%)" class="slide slide--finale">
      <p>Aus all diesen Gründen</p>
      <p>und aus vielen mehr</p>
      <p>ist meine Mama die beste Mama.</p>
    </section>

    <section data-name="gratulation" data-bg="hsl(352,65%,35%)" class="slide slide--gratulation">
      <p>Alles Gute zum Geburtstag!</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showArrow: true,
      bgCol: 'white',
      slideData: [],
    }
  },
  computed: {
    containerStyle() {
      return {
        '--bg': this.bgCol,
      }
    },
  },
  mounted() {
    this.setupBgColorData()
  },
  methods: {
    setupBgColorData() {
      const slideNodes = document.querySelectorAll('.slide')
      const slidesArray = [...slideNodes]
      slidesArray.forEach(el => {
        this.slideData.push({
          name: el.dataset.name,
          background: el.dataset.bg,
          offset: el.offsetTop,
          height: el.getBoundingClientRect().height,
        })
      })
      this.determineBgColor()
      document.addEventListener('scroll', this.determineBgColor)
      document.addEventListener('scroll', this.removeArrow)
    },
    determineBgColor() {
      const distanceScrolled = window.pageYOffset
      const viewportHeight = window.innerHeight
      const offset = distanceScrolled + viewportHeight / 2
      const currentSlide = this.slideData.find(
        slide => slide.offset < offset && slide.offset + slide.height >= offset
      )
      this.bgCol = currentSlide.background
    },
    removeArrow() {
      this.showArrow = false
      document.removeEventListener('scroll', this.removeArrow)
    },
  },
}
</script>

<style>
#app {
  --bg: white;
  background-color: var(--bg);
  transition: background-color 500ms linear;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.slide img {
  height: min(60vh, 80vw);
}

.slide p {
  max-width: 85%;
  font-size: calc(min(8vw, 2.5rem));
  text-align: center;
  line-height: 1.35;
}

.slide--start p {
  font-size: calc(min(11vw, 3.25rem));
  color: hsl(352, 100%, 35%);
}

.slide--start .arrow {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 250ms linear;
}

.slide--start .arrow.hidden {
  opacity: 0;
}

.slide--aufpassen img {
  height: min(50vh, 80vw);
  margin-bottom: 5vmin;
  filter: invert(27%) sepia(46%) saturate(459%) hue-rotate(8deg) brightness(91%) contrast(88%);
}

.slide--aufpassen p {
  color: hsl(45, 35%, 25%);
}

.slide--klavier img {
  filter: invert(24%) sepia(31%) saturate(589%) hue-rotate(64deg) brightness(98%) contrast(89%);
}

.slide--klavier p {
  color: hsl(110, 25%, 25%);
}

.slide--schulweg img {
  margin-bottom: -5vmin;
  filter: invert(28%) sepia(56%) saturate(350%) hue-rotate(125deg) brightness(89%) contrast(92%);
}

.slide--schulweg p {
  color: hsl(175, 35%, 25%);
}

.slide--zuhören img {
  height: min(50vh, 80vw);
  filter: invert(23%) sepia(46%) saturate(436%) hue-rotate(182deg) brightness(100%) contrast(94%);
}

.slide--zuhören p {
  color: hsl(220, 30%, 30%);
}

.slide--computer img {
  height: min(50vh, 80vw);
  filter: invert(20%) sepia(29%) saturate(844%) hue-rotate(251deg) brightness(93%) contrast(90%);
}

.slide--computer p {
  color: hsl(300, 25%, 25%);
}

.slide--musik img {
  height: min(50vh, 80vw);
  filter: invert(22%) sepia(13%) saturate(2064%) hue-rotate(328deg) brightness(100%) contrast(81%);
}

.slide--musik p {
  color: hsl(15, 35%, 30%);
}

.slide--finale p {
  color: hsl(355, 75%, 25%);
}

.slide--gratulation {
  color: white;
}

.slide--gratulation p {
  font-size: calc(min(15vw, 4rem));
}
</style>
